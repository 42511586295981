<template>
  <section id="pengajuan-master-kontak">
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-card>
          <div class="d-flex justify-content-end mb-2">
            <b-link
              href="/pengajuan/kontak/baru"
              class="btn btn-success btn-sm"
            >
              Tambah Kontak Penanggung Jawab
            </b-link>
          </div>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              trigger: 'enter',
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length
            }"
            @on-search="onSearch"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <!-- Column: Action -->
              <span v-if="props.column.field == 'action'">
                <b-button
                  v-b-tooltip.hover
                  :to="{ name: 'pengajuan-ubah-kontak', params: { id: props.row.master_pic_id } }"
                  variant="primary"
                  size="sm"
                  class="mr-50 mb-25"
                  title="Ubah Data Penanggung Jawab"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Data Kontak Penanggung Jawab?
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BButton, BCol, BCard, BPagination, BFormGroup, BFormInput, BFormSelect, VBTooltip, BLink, VBModal, BModal, BCardText,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

import ProfileAbout from '../module/PengajuanAbout.vue'
import ProfileSideMenu from '../module/PengajuanSideMenu.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BButton,
    BLink,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BModal,
    BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ProfileAbout,
    ProfileSideMenu,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      userData: getUserData(),
      isLoading: false,
      columns: [
        {
          thClass: 'text-center',
          label: 'Nama',
          field: 'nama',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama',
          },
        },
        {
          thClass: 'text-center',
          label: 'Nomor Kontak',
          field: 'pic_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Nomor Kontak',
          },
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      totalRecords: 0,
      serverParams: {
        sort_by: [
          {
            column: 'created_on',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 25,
      },
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onSearch(params) {
      this.updateParams({ keyword: params.searchTerm })
      setTimeout(() => {
        this.loadItems()
      }, 2000)
    },
    onPageChange(params) {
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = []
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        filterz.push({
          column: key,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.rows = []
      this.$http.post(`/master/master-pic/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          if (res.data.status === 'success') {
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
    },
    removeContact(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/masters/contact/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(res => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem(res)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
